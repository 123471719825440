<template>
	<user-layout>
		<div class="w-full py-6 mx-auto">
			<div class="flex flex-col items-start justify-between xs:flex-row">
				<section-title title="Patient Logs" description="View the relevant data and other details" />
				<!-- todo -->
				<!-- <export-button v-if="!timeInStatus" routeLink="/perpetrator/index" class="w-full mt-3 xs:mt-0 xs:w-auto" /> -->
			</div>
			<form class="md:mt-4 mt-6 flex items-start md:space-x-2.5 md:flex-row flex-col md:space-y-0 space-y-6">
				<date-picker
                    :disabled="true"
                    :enableTimePicker="true"
					id="date_time_in"
					name="date_time_in"
					label="Date & Time In"
					v-model="currentDate"
					class="w-full md:w-auto"
				/>
				<div class="md:w-[600px] w-full">
					<multi-select
						:options="patientsOptions"
						id="patient_name"
						name="patient_name"
						label="Patient Name"
						placeholder="Select.."
						searchable
						v-model="patient" 
						@update:modelValue="(value:any) => (selectedPatient(value))"
					/>
				</div>
				<template v-if="form.patient_id">
					<button-component
						v-if="is_logout"
						@click="showTimeIn = true"
						style="margin-top: 24px !important;"
						customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 py-[14px] shrink-0 md:w-auto w-full">
						<span>Log-in</span>
					</button-component>
					<button-component
						v-if="!is_logout"
						@click="showTimeOut = true"
						style="margin-top: 24px !important;"
						customClass="bg-red-600 hover:bg-red-700 text-white transition border border-red-600 hover:border-red-700 py-[14px] shrink-0 md:w-auto w-full">
						<span>Log-out</span>
					</button-component>
				</template>
                <button-component
                    @click="router.push('/patient/create')"
                    style="margin-top: 24px !important;"
                    customClass="bg-gray-600 hover:bg-gray-700 text-white transition border py-[14px] shrink-0 md:w-auto w-full">
                    <span>Add New Patient</span>
                </button-component>
			</form>
			<div class="flex items-center md:space-x-2.5 md:flex-row flex-col md:space-y-0 space-y-6 md:mt-0 mt-6">
				<div class="w-full">
					<text-input
						textarea
						label="Remarks"
						placeholder="Remarks"
						name="remarks"
						id="remarks"
						:textAreaRows="1"
						v-model="form.remarks" />
				</div>
				<button-component btnWidth="py-[14px] mt-6 md:w-[120px] w-full" style="margin-top: 24px !important;" @click="showFilter = true">
					<span>Filter</span>
				</button-component>
			</div>

			<FilterPanel
				:show="showFilter"
				@close="showFilter = false"
				@apply:reset="applyReset()"
				@apply:filters="applyFilters()"
                customClass="!overflow-visible overflow-x-visible"
                >
				<template #fields>
					<div class="space-y-5">
						<date-picker
							id="visit_dates"
							name="visit_dates"
							label="Visit Dates"
							:range="true"
							class="filter-range"
                            multiCalendars
							v-model="filterDate" />
					</div>
				</template>
			</FilterPanel>
			<div class="py-6">
				<data-table
					:headers="headers"
					:no-action="true"
					:count="items?.length"
					@update:searchText="(value: string) => (filterSearch = value)"
					tableClass="header-frm-table three four">
					<template v-slot:body>
						<template v-for="item in items" :key="item">
							<tr>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ item.patient?.name }}
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ item.remarks }}
								</td>
								<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
									{{ DateFormatter.customDateFormat(item.time_in, 'MMM dd, yyy - hh:mm a ') }}
								</td>
								<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
									{{  item.time_out ? DateFormatter.customDateFormat(item.time_out, 'MMM dd, yyy - hh:mm a ') : "-" }}

								</td>
							</tr>
						</template>
					</template>
					<template #tfoot>
						<div class="w-full px-6 border-t py-7">
							<minify-pagination
								:numRows="numRows"
								@update:rows="(value: any) => changeRows(value)"
								@update:page="(value: number) => paginate(value)"
								:current-page="page"
								:last-page="lastPage"></minify-pagination>
						</div>
					</template>
				</data-table>
			</div>
		</div>

		<time-in-modal
			:show="showTimeIn"
			@cancel="showTimeIn = false"
			@confirm="login()"
			cancel-text="Cancel"
			action-text="Log-in"
			content="Are you sure you wish to save this record?">
			<template #fields>
				<div class="flex items-center justify-center space-x-3 text-left">
					<date-picker
						id="time_in_date"
						name="time_in_date"
						label="Date"
						class="patient-logs-date"
						v-model="form.date"
						:error="errors?.date" 
						:minDate="minDate"
					/>
					<time-picker
						:disabled="!form.date"
						id="time_in_time"
						name="time_in_time"
						label="Time"
						class="patient-logs-date"
						placeholder="HH:MM"
						timePicker
						enableTimePicker
						v-model="timeIn"
						:error="errors?.time_in" 
						:minDate="minDate"
						@update:modelValue="(value:any) => (setTime(value))"
					/>
				</div>
			</template>
		</time-in-modal>
		<success-modal
			:show="showTimeInSuccess"
			@confirm="redirect('timeIn')"
			action-text="Close"
			title="Patient Log Recorded!"
			content="New patient log has been successfully saved" />

		<time-out-modal
			:show="showTimeOut"
			@cancel="showTimeOut = false"
			@confirm="logout()"
			cancel-text="Cancel"
			action-text="Log-out"
			content="Are you sure you wish to save this record?">
			<template #fields>
				<div class="flex items-center justify-center space-x-3 text-left">
					<date-picker
						id="time_in_date"
						name="time_in_date"
						label="Date"
						class="patient-logs-date"
						v-model="form.date"
						:error="errors?.date" 
						:minDate="minDate"
					/>
					<time-picker
						id="time_in_time"
						name="time_in_time"
						label="Time"
						class="patient-logs-date"
						placeholder="HH:MM"
						timePicker
						enableTimePicker
						v-model="timeOut"
						:error="errors?.time_out"
						:minDate="minDate"
						@update:modelValue="(value:any) => (setTime(value))"
					/>
				</div>
			</template>
		</time-out-modal>
		<success-modal
			:show="showTimeOutSuccess"
			@confirm="redirect('timeOut')"
			action-text="Close"
			title="Patient Log Recorded!"
			content="New patient log has been successfully saved" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import DataTable from "@/components/partials/DataTable.vue";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import TimePicker from "@/components/inputs/TimePicker.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ExportButton from "@/components/buttons/ExportButton.vue";
import TimeInModal from "./Components/TimeInModal.vue";
import TimeOutModal from "./Components/TimeOutModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import PatientLogService from "@/classes/Settings/PatientLogService";
import pickBy from "lodash/pickBy";
import { errorResponse } from "@/helpers/errorMessage";
import DateFormatter from "@/helpers/DateFormatter";

interface timeFormInterface {
	hours: number | null;
	minutes: number | null;
	seconds: number | null;
}

const route = useRoute();
const router = useRouter();

const showFilter = ref<boolean>(false);
const showTimeIn = ref<boolean>(false);
const showTimeInSuccess = ref<boolean>(false);
const showTimeOut = ref<boolean>(false);
const showTimeOutSuccess = ref<boolean>(false);
const items = ref<any>(null);
const page = ref<any>(route.query.page);
const lastPage = ref<any>(null);
const rows = ref<any>(route.query.rows || 10);
const numRows = computed(() => (rows.value ? rows.value + " Rows" : "All"));
const filterDate = ref<any>(route.query.visit_date);
const currentDate = ref<Date>(new Date());
const is_logout = ref<boolean>(true);
const errors = ref<object | null>(null);
const timeIn = ref<Date>(new Date());
const timeOut = ref<Date>(new Date());
const patient = ref<number | null>(null);
const minDate = new Date();

const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "Patient Name" },
	{ text: "Remarks" },
	{ text: "Time In", haveSort: true },
	{ text: "Time Out", haveSort: true },
];

const patientsOptions = ref<[]>([]);

const form = ref<{ patient_id: string; date: Date; time_in: string; time_out: string; remarks: string }>({
	patient_id: "",
	date: new Date(),
	time_in: "",
	time_out: "",
	remarks: "",
});

const resetForm = () => {
	form.value.patient_id = "";
	form.value.time_in = "";
	form.value.time_out = "";
	form.value.remarks = "";
	form.value.date = new Date();
};

const applyFilters = () => {
	resetForm();
	reloadPage().then(() => {
		showFilter.value = false;
		getData();
	});
};

const applyReset = () => {
	filterDate.value = "";
	reloadPage().then(() => {
		getData();
		showFilter.value = false;
	});
};

const changeRows = (row: any): void => {
	rows.value = row;
	applyFilters();
};

const paginate = (data: number): void => {
	page.value = data;
	applyFilters();
};

const reloadPage = async () => {
    if (filterDate.value) {
        filterDate.value = filterDate.value?.map((item: string) => {
            const date = new Date(item);
            return date.toISOString();
        });
    }
	return await router.push({
		path: "/patient-logs/index",
		query: pickBy({
			visit_date: filterDate.value,
			page: page.value,
			rows: rows.value,
		}),
	});
};

const getData = (patientId: number = null) => {
	PatientLogService.index({
		visit_date: filterDate.value,
		patient_id: patientId,
		page: page.value,
		rows: rows.value,
	})
		.then((response: object) => {
			items.value = response.data.items.data;
			page.value = response.data.items.current_page;
			lastPage.value = response.data.items.last_page;
			patientsOptions.value = response.data.patients;

			if (patientId) {
				PatientLogService.checkStatus({
					patient: patientId,
				})
					.then((response: object) => {
						is_logout.value = response.data.is_logout;
                        form.value.remarks = response.data.is_logout ? null : response.data.remarks;
					})
					.catch((error: object) => {
						is_logout.value = false;
						console.log(error);
					});
			}
		})
		.catch((error: any) => console.log(error));
};

const redirect = (log = "timeIn") => {
	if (log == "timeIn") {
		showTimeInSuccess.value = false;
	} else {
		showTimeOutSuccess.value = false;
	}
	getData(form.value.patient_id);
};

const login = () => {
	PatientLogService.login(
		{
			patient: form.value.patient_id,
		},
		form.value
	)
		.then((response: object) => {
			showTimeInSuccess.value = true;
			showTimeIn.value = false;
			console.log(response);
		})
		.catch((error: object) => (errors.value = errorResponse(error.response?.data?.errors)));
};

const logout = () => {
	PatientLogService.logout(
		{
			patient: form.value.patient_id,
		},
		form.value
	)
		.then((response: object) => {
			showTimeOutSuccess.value = true;
			showTimeOut.value = false;
            form.value.remarks = null;
		})
		.catch((error: object) => (errors.value = errorResponse(error.response?.data?.errors)));
};

const selectedPatient = (patient:number) => {
	getData(patient);
	form.value.patient_id = patient;
}

const setTime = (data:any):void => {
	form.value.date.setHours(data.hours);
	form.value.date.setMinutes(data.minutes);
	form.value.date.setSeconds(data.seconds);
	console.log(form.value)
} 

onMounted(() => getData());
</script>
