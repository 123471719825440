import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
import { createCase } from "../Interface/CaseHistory";
import Relationships from "@/classes/Forms/Relationships";
import PatientOfflineForm from "@/classes/Patients/Offline/Formatters/PatientOfflineForm";
import CaseComorbiditiesOfflineForm from "./CaseComorbiditiesOfflineForm";
import CaseComorbitiesProcessor from "../processor/CaseComorbitiesProcessor";
import CaseDiagnosesOfflineForm from "./CaseDiagnosesOfflineForm";
import CaseDiagnosesProcessor from "../processor/CaseDiagnosesProcessor";
import CaseMentalHealthOfflineForm from "./CaseMentalHealthOfflineForm";
import CaseRemarksOfflineForm from "./CaseRemarksOfflineForm";
import DefaultFormStructureBuild from "./DefaultFormStructureBuild";
import PerpetratorProcessor from "@/classes/Perpetrators/Offline/Processor/PerpetratorProcessor";
import PerpetratorOfflineForm from "@/classes/Perpetrators/Offline/Formatters/PerpetratorOfflineForm";

const relationship = new Relationships();
class CaseOfflineForm {
    /**
     * Template form structure of case table
     */
    formStructure () {
        return {
            id: null,
            case_number: null,
            patient_id: null,
            initiated_at: null,
            is_followup: null,
            gender: null,
            is_reabuse: false,
            incident_time: null,
            birthday_classification: null,
            birthdate: null,
            approximate_age_year: null,
            approximate_age_month: null,
            civil_status_id: null,
            nationality_id: null,
            religion_id: null,
            child_type: null,
            is_internally_displaced: false,
            disability_id: null,
            educational_attainment_id: null,
            employment_status_id: null,
            company_name: null,
            company_contact_number: null,
            company_address: null,
            hospital_number: null,
            hospital_area_id: null,
            is_inpatient: false,
            is_no_guardian: false,
            first_name: null,
            middle_name: null,
            last_name: null,
            office_id: null,
            other_religion: null,
            encoded_by: null,
            indigeneous_people_id: null,
            sex_at_birth: null,
            assigned_mss: null,
            endorsed_to_id: null,
            pwd: null,
            other_indigeneous_people: null,
            other_nationality: null,
        }
    }

    fillCaseForm(item: any) {
        const builder: any = {};
        const items = this.formStructure()
        // get default form structure template
        for (const index in items) {
            // match all existing indexes based on default form structure
            builder[index] = DefaultFormStructureBuild.fill(index, item);
        }
        return this.otherEditCaseForm(builder, item);
    }

    otherEditCaseForm(builder: any, item: any) {
        builder.patient_id = item.patient_id || item.id;
        builder.comorbidities = CaseComorbiditiesOfflineForm.editForm(item);
        builder.mental_health = CaseMentalHealthOfflineForm.editForm(item);
        builder.remarks = CaseRemarksOfflineForm.editForm(item);
        // diagnoses
        builder.primary_diagnoses_id = item?.diagnoses?.primary_diagnoses_id || null;
        builder.other_diagnoses = item?.diagnoses?.other_diagnoses || null;
        builder.high_suicidal_risk = item?.diagnoses?.high_suicidal_risk;
        builder.is_hama = item?.diagnoses?.is_hama || false;
        // referral service
        builder.referral_source_id = item?.referral_service?.referral_source_id || null;
        builder.referral_specify = item?.referral_service?.referral_specify || null; 
        builder.second_referral_source_id = item?.referral_service?.second_referral_source_id || null;
        builder.sought_service_id = item?.referral_service?.sought_service_id || null;
        builder.intake_social_worker_id = item?.referral_service?.intake_social_worker_id || null;
        builder.social_worker_id = item?.referral_service?.social_worker_id || null;
        builder.physician_id = item?.referral_service?.physician_id || null;
        builder.mental_health_personnel_id = item?.referral_service?.mental_health_personnel_id || null;
        builder.mms_id = item?.referral_service?.mms_id || null;
        builder.trainee_id = item?.referral_service?.trainee_id || null;
        return builder;
    }

    renderSyncToOffline(item: Array<createCase>) {
        const builder: any = {};
        const items = this.formStructure()
        // get default form structure template
        for (const index in items) {
            // match all existing indexes based on default form structure
            builder[index] = DefaultFormStructureBuild.fill(index, item);

            // filter all dates fields and convert value into ISO
            const check = this.checkFormStrucuteDates().find((item) => item === index);
            if (check) {
                builder[index] = relationship.dateFormatFromIso(builder[index]);
            }
        }

        return builder;
    }

    async renderCaseCardHistory(item: any) {
        const cardHistory = await CaseComorbitiesProcessor.getCardCaseHistory(item.id);
        const diagnosisCardHistory = await CaseDiagnosesProcessor.getCardCaseHistory(item.id);
        const getLinkedPerpetrators = await PerpetratorProcessor.getLinkedPerpetrators(item.id);
        item.initiated_at_formatted = relationship.dateFormat(item.initiated_at, 'MMMM dd, yyyy')
        item.comorbidities = CaseComorbiditiesOfflineForm.renderCaseHistoryCard(cardHistory)
        item.diagnoses = CaseDiagnosesOfflineForm.renderCaseHistoryCard(diagnosisCardHistory)
        item.perpetrators = await PerpetratorOfflineForm.renderPerpetrators(getLinkedPerpetrators)
        return item;
    }

    async renderSearchPatientCase(item: any, payload: any) {
        if (payload.mode != 'patient') {
            const response = await CaseDiagnosesProcessor.getPrimaryDiagnoses(item.id);
            item.primary_diagnosis = response;
        }

        return await PatientOfflineForm.renderPatientSearch(item);
    }

    private checkFormStrucuteDates () {
        return [
            'birthdate',
            'initiated_at'
        ]
    }

    store(payload:any) {
        const form = this.renderSyncToOffline(payload);
        form.is_sync = false;
        form.synching_remarks = null;
        form.is_created_offline = true;
        form.created_at = relationship.dateFormatFromIso(new Date);
        return form;
    }

    update(payload: any) {
        const form = this.renderSyncToOffline(payload);
        form.id = payload.cases_id || payload.id;
        form.is_sync = false;
        form.synching_remarks = null;
        form.is_updated_offline = true;
        form.updated_at = relationship.dateFormatFromIso(new Date);
        return form;
    }
}

export default new CaseOfflineForm();