<template>
    <session-lock-modal 
        :show="showSessionLock"
        @cancel="logoutUser"
        @confirm="(value: boolean) => validate(value)"
        :title="title"
        content="Please input your pin code to continue"
        action-text="Unlock"
        whiteBg
        validate
        :is-network-available="isNetworkAvailable"
    />
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";

import SessionLockModal from '@/components/modals/SessionLockModal.vue';
import { logout } from "@/classes/AuthService";
import Storage from "@/helpers/storage";
import { useNetworkStore } from "@/store/network";
import { useSecurityFeature } from "@/store/securityFeature";

let idleTime = 0; // This variable will store the idle time in milliseconds

const storage = new Storage();

const showSessionLock = ref<boolean>(false);
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const title = ref('Session Locked!');
const titlePinCode = ref('Enter your pin code!');

const validate = (status: boolean): void => {
    if (status) {
        useSecurityFeature().isPasswordBlocker = false;
        sessionStorage.setItem('hasPasswordBlocker', false);
        // localStorage.setItem('isPasswordBlockerValidated', true);
        showSessionLock.value = false;
        storage.clearItem('isSessionLock');
        idleInterval = setInterval(checkIfIdle, 10000);
        removeSessionModal();
    }
}

const lockSession = (): void => {

    const user = storage.get('user');
    if(user){
        showSessionLock.value = true;
        storage.set('isSessionLock', true);
    } else {
        showSessionLock.value = false;
    }
}

// This function will reset the idle time to zero
const resetIdleTime = (): void => {
    idleTime = 0;
}

// This function will check if the itruedle time has reached 5 minutes (300000 milliseconds)
const checkIfIdle = (): void => {
    idleTime += 1000; // Increment the idle time by one second
    console.log(idleTime, parseInt(process.env.VUE_APP_SESSION_LOCK)); // Log the idle time for debugging
    if (eval(storage.get('isSessionLock'))) {
        clearInterval(idleInterval);
    }

    if (idleTime >= parseInt(process.env.VUE_APP_SESSION_LOCK)) { // If the idle time is set value in .env or more
        lockSession(); // Show the popup
        clearInterval(idleInterval); // Stop the interval
    }
}

// This will set an interval to call the checkIfIdle function every second
let idleInterval = setInterval(checkIfIdle, 10000);

// These will add event listeners for keypress and mousemove events and call the resetIdleTime function
document.addEventListener("keypress", resetIdleTime);
document.addEventListener("mousemove", resetIdleTime);

const logoutUser = (): void => {
    showSessionLock.value = false;
    storage.clearItem('isSessionLock')
    const user = storage.get('user');
    logout();
}

const removeSessionModal = () => {
    const modals = document.querySelectorAll('#headlessui-portal-root > div');
    // Remove all child divs except the last one
    Array.from(modals).slice(0, -1).forEach(div => div.remove());
}

watch(
    () => useSecurityFeature().isPasswordBlocker,
    (status) => {
        if (!eval(storage.get('isSessionLock'))) {
                if (!isNetworkAvailable.value) {
                showSessionLock.value = status;
                if (status) {
                    title.value = titlePinCode.value;
                }
            }

            if (isNetworkAvailable.value) {
                showSessionLock.value = false;
            }
        }
    }
);

onMounted(() => {
    showSessionLock.value = false;
    title.value = 'Session Locked!';
    if (eval(storage.get('isSessionLock'))) {
        lockSession()
        clearInterval(idleInterval);
    }
})
</script>